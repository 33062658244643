import React from 'react';

import Icon, { IconName } from '~/components/Icon';
import { useSound } from '~/hooks/useSound';
import { cn } from '~/utils/cn';

export type ButtonProps = {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  as?: React.ElementType;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'green' | 'purple' | 'blue' | 'red';
  size?: 'small' | 'large';
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: IconName;
};

const Button = React.forwardRef(
  (
    {
      as: Component = 'button',
      href,
      onClick,
      type = 'button',
      variant = 'green',
      size = 'large',
      children,
      disabled = false,
      icon,
      className,
      ...props
    }: ButtonProps &
      React.ButtonHTMLAttributes<HTMLButtonElement> &
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
    ref
  ): JSX.Element => {
    const playClickSound = useSound('/sounds/click_003.mp3');
    const playDisabledSound = useSound('/sounds/error_002.mp3');

    const clickSound = () => {
      if (!disabled) {
        playClickSound();
      } else {
        playDisabledSound();
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === ' ') {
        clickSound();
      }
    };

    return (
      <Component
        ref={ref}
        onClick={onClick}
        onMouseDown={clickSound}
        onKeyDown={handleKeyDown}
        type={type}
        className={cn(
          'relative w-fit group shadow-[0px_0px_0px_2px_#000000,0px_6px_0px_#00000040] uppercase focus-visible:outline-transparent transition-all duration-150 focus-visible:outline-offset-4',
          'after:absolute after:inset-x-0 after:top-0 after:transition-all after:duration-150 focus-visible:after:-top-[2px] hover:after:-top-[2px] active:after:top-0 after:shadow-[0px_2px_0px_0px_#FFFFFF40_inset]',
          'before:absolute before:-inset-x-0 before:bottom-5 before:top-0 before:rounded-2xl before:bg-black before:transition-all before:duration-150 focus-visiblebefore:-top-0.5 hover:before:-top-0.5 active:before:-top-0 before:shadow-[0px_0px_0px_2px_#000000]',
          size === 'small' &&
            'rounded-xl py-[11px] px-[15px] after:rounded-xl after:bottom-[3px] focus-visible:after:bottom-[5px] hover:after:bottom-[5px] active:after:bottom-[3px]',
          size === 'large' &&
            'rounded-2xl py-4 px-5 after:rounded-2xl after:bottom-[4px] focus-visible:after:bottom-[6px] hover:after:bottom-[6px] active:after:bottom-[4px]',
          variant === 'green' && 'bg-[#357F34] after:bg-green-500',
          variant === 'purple' && 'bg-[#413677] after:bg-purple-500',
          variant === 'blue' && 'bg-[#2D88A9] after:bg-blue-500',
          variant === 'red' && 'bg-[#a41f4b] after:bg-red-500',
          disabled && 'cursor-not-allowed opacity-50',
          icon && 'flex items-center justify-center p-0',
          icon && size === 'small' && 'aspect-square size-10',
          icon && size === 'large' && 'aspect-square size-14',
          className
        )}
        {...(href && { href })}
        {...props}
      >
        {icon && (
          <Icon
            name={icon}
            size={6}
            className="relative -top-px z-10 transition-transform duration-150 group-hover:-translate-y-0.5 group-active:-translate-y-0"
          />
        )}
        <span
          className={cn(
            icon && 'sr-only',
            !icon &&
              'relative z-10 inline-block transition-transform duration-150 group-hover:-translate-y-0.5 group-active:-translate-y-0',
            size === 'small' && 'h-base -top-px',
            size === 'large' && 'h-lg'
          )}
        >
          {children}
        </span>
      </Component>
    );
  }
);

Button.displayName = 'Button';

export default Button;
